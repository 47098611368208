import React from 'react';
import { Link, navigate } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Toolbar, Typography, Button, Tooltip, Grid, Container } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import StandaloneNavLinks from './nav/StandaloneNavLinks';
import MobileNavBar from './nav/MobileNavBar';
//import { BsToggleOn, BsToggleOff } from 'react-icons/bs';

import { StaticImage } from 'gatsby-plugin-image';

import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  appBar: {
    backgroundColor: '#ffffff',
    color: theme.palette.text.primary,
    padding: '0 0',
    borderTop: '1.3px #ECECEE solid',
    minHeight: '64px',
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    position: 'sticky',
    boxShadow: '0px 3px 8px 0 rgba(0, 0, 0, 0.05)',
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
    [theme.breakpoints.down('xs')]: {
      borderTop: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
  },
  cta: {
    fontSize: '20px',
    marginLeft: '10px',
    color: '#ffffff',
    [theme.breakpoints.down('lg')]: {
      padding: '12px 20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  imgLogo: {
    maxWidth: '225px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '155px',
    },
  },
}));

export interface HeaderProps {
  switchTheme: () => void;
  siteTitle?: string;
}

const Header: FC<HeaderProps> = (props) => {
  //const [showOn, setShowOn] = useState<boolean>(false);
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');

  return (
    <AppBar component="header" position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters={true} style={{ width: '100%' }}>
        <Container className={classes.root} fixed={!med ? true : false}>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs={6}
              md={2}
              xl={3}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Link to="https://www.workwave.com" target="_blank"  aria-label="Opens in a new tab">
                <StaticImage
                  src="../../images/logos/footer-logo.svg"
                  alt="logo"
                  className={classes.imgLogo}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </Link>
            </Grid>
            <Grid
              item
              xs={6}
              md={10}
              xl={9}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {med ? (
                <MobileNavBar />
              ) : (
                <>
                  <StandaloneNavLinks />
                  <a href="https://jobs.lever.co/workwave" target="_blank"  aria-label="Opens in a new tab" rel="noreferrer">
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className={classes.cta}
                    >
                      Browse Jobs
                    </Button>
                  </a>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

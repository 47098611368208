import React from 'react';

import MobileNav from './MobileNav';
import MobileStandaloneNavLinks from './MobileStandaloneNavLinks';

const MobileNavBar = () => {
  return (
    <MobileNav>
      <MobileStandaloneNavLinks link="perks" title="Perks & Benefits" close={close} />
      <MobileStandaloneNavLinks link="numbers" title="By the Numbers" close={close} />
      <MobileStandaloneNavLinks link="values" title="Core Values" close={close} />
      <MobileStandaloneNavLinks link="interview" title="Interview Process" close={close} />
    </MobileNav>
  );
};

export default MobileNavBar;

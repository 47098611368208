import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Popper, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { usePopupState, bindPopper, bindToggle } from 'material-ui-popup-state/hooks';

// import { MobileNavLink } from './MobileNavLink';
// import { MobileStandaloneNavLink } from './MobileStandaloneNavLink';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#002D5C',
    textDecoration: 'none',
  },
  cta: {
    margin: '0 1rem',
    fontSize: '16px',
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8rem',
      padding: '10px 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px 8px',
    },
  },
  desktop: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const MobileNav = ({ children }) => {
  const classes = useStyles();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'mobileMenu',
  });
  const { isOpen, close } = popupState;
  const browser = typeof window !== 'undefined';

  const childrenWithClose = React.Children.map(children, (child) =>
    React.cloneElement(child, { close })
  );

  useEffect(() => {
    let body;
    if (browser) {
      body = document.querySelector('body');
    }

    const popperScroll = () => {
      isOpen ? (body.style.overflow = 'hidden') : (body.style.overflow = 'visible');
    };

    popperScroll();
  }, [isOpen]);

  return (
    <>
      {/* <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={(e) => handleModalClick(e)}
        className={classes.cta}
      >
        Browse Jobs
      </Button> */}
      <a href="https://jobs.lever.co/workwave" target="_blank" aria-label="Opens in a new tab" rel="noreferrer">
        <Button variant="contained" size="medium" color="primary" className={classes.cta}>
          Browse Jobs
        </Button>
      </a>
      <div
        {...bindToggle(popupState)}
        className={classes.desktop}
        style={{
          height: '64px',

          alignItems: 'center',
        }}
      >
        {isOpen ? <CloseIcon color="primary" /> : <MenuIcon color="primary" />}
      </div>

      <Popper
        className={classes.mobileMenu}
        style={{
          marginRight: '-5px',
          width: '100vw',
          overflow: 'scroll',
          height: '100vh',
          background: '#002D5C',
          paddingBottom: '300px',
        }}
        placement="top"
        disablePortal={true}
        modifiers={{
          offset: {
            enabled: true,
            offset: '300,0',
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
        {...bindPopper(popupState)}
      >
        <div>{childrenWithClose}</div>
      </Popper>
    </>
  );
};

export default MobileNav;

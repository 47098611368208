import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme, ThemeProvider } from '@material-ui/core/styles';
import { library } from '@fortawesome/fontawesome-svg-core';

import MetaLayout from './meta-layout';
import { FCR, themes, getCookie, setCookie, themeCookie } from '../../util';

//duotone
import {
  faIslandTropical,
  faCalendar,
  faBabyCarriage,
  faPiggyBank,
  faCircleHeart,
  faMoonCloud,
  faPlane,
  faShieldPlus,
  faCircleStar,
  faGraduationCap,
  faSackDollar,
  faBadgePercent,
  faQuoteRight,
  faHandHoldingHeart,
  faUmbrella,
  faStethoscope,
  faComments,
  faMicrochip,
  faCheckCircle,
  faSquareHeart,
  faThumbsUp,
  faSeedling,
  faFaceSmileBeam,
  faHouseUser,
  faLightbulbCflOn,
  faLightbulbOn,
  faHandFist,
  faHandsHoldingCircle,
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faClipboardListCheck,
  faCameraWeb,
  faPeopleGroup,
  faFaceGrinWide,
  faHandshakeAngle,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';

//brand
import {
  faSquareFacebook,
  faSquareXTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import 'typeface-poppins';
import 'typeface-open-sans';
import 'typeface-roboto';

library.add(
  faSquareFacebook,
  faSquareXTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
  faIslandTropical,
  faCalendar,
  faBabyCarriage,
  faPiggyBank,
  faCircleHeart,
  faMoonCloud,
  faPlane,
  faShieldPlus,
  faCircleStar,
  faGraduationCap,
  faSackDollar,
  faBadgePercent,
  faQuoteRight,
  faClipboardListCheck,
  faCameraWeb,
  faPeopleGroup,
  faFaceGrinWide,
  faHandshakeAngle,
  faHandHoldingHeart,
  faUmbrella,
  faStethoscope,
  faComments,
  faMicrochip,
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
  faSquareHeart,
  faThumbsUp,
  faSeedling,
  faFaceSmileBeam,
  faHouseUser,
  faLightbulbOn,
  faHandFist,
  faHandsHoldingCircle
);

const Layout: FCR = (props) => {
  const [theme, setTheme] = useState<Theme>(themes[getCookie(themeCookie) || 'light']);
  const switchTheme = (): void => {
    setTheme((prev) => {
      const newTheme = prev.palette.type === 'dark' ? 'light' : 'dark';
      setCookie(themeCookie, newTheme);
      return themes[newTheme];
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MetaLayout switchTheme={switchTheme}>{props.children}</MetaLayout>
    </ThemeProvider>
  );
};

export default Layout;

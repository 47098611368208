import deepMerge from 'deepmerge';
import { createTheme, responsiveFontSizes, ThemeOptions, Theme } from '@material-ui/core/styles';

const makeTheme = (variant: ThemeOptions): Theme => {
  const common = {
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.5rem',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
      },
    },
  };
  const theme = createTheme(deepMerge(common, variant));
  return theme;
};

const light: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#002D5C',
    },
    secondary: {
      main: '#2F7FC1',
    },
    error: {
      main: '#f03e3e',
    },
    warning: {
      main: '#f0a04f',
    },
    info: {
      main: '#709ecc',
    },
    success: {
      main: '#4fe054',
    },
    text: {
      primary: '#343a40',
      secondary: '#2e3133',
      hint: '#363c42',
      disabled: '#48494a',
    },
    background: {
      default: '#fffff',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  props: {
    MuiContainer: {
      maxWidth: 'lg',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '3.75rem',
        //60px
        fontWeight: 700,
        letterSpacing: '-1.5px',
      },
      h2: {
        fontSize: '2.25rem',
        //36px
        fontWeight: 600,
        letterSpacing: '-.5px',
        lineHeight: 1.2,
      },
      h3: {
        fontSize: '2rem',
        //32px
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.75rem',
        //28px
        fontWeight: 600,
        letterSpacing: '.25px',
      },
      h5: {
        fontSize: '1.5rem',
        //24px
        fontWeight: 600,
      },
      h6: {
        fontSize: '1.25rem',
        //20px
        fontWeight: 600,
        letterSpacing: '.15px',
        lineHeight: 1.3,
      },
      subtitle1: {
        fontSize: '1rem',
        //16px
        lineHeight: 1,
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: '.875rem',
        //14px
        lineHeight: 1,
        fontWeight: 500,
      },
      body1: {
        fontSize: '1.125rem',
        //18px
        lineHeight: 1.4,
        // fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#4B5B69',
      },
      body2: {
        fontSize: '1rem',
        //16px
        lineHeight: 1,
        // fontFamily: 'Roboto',
        fontWeight: 400,
        color: '#4B5B69',
      },
      caption: {
        fontSize: '.75rem',
        //12px
        lineHeight: 1,
        fontWeight: 400,
      },
      overline: {
        fontSize: '.75rem',
        //12px
        lineHeight: 1,
        fontWeight: 400,
        textTransform: 'uppercase',
      },
      // Available in v5
      // avatarLetter: {
      //   fontSize: '1.25rem',
      //   lineHeight: 1,
      //   fontWeight: 400,
      // },
      // inputLabel: {
      //   fontSize: '.75rem',
      //   lineHeight: 1,
      //   fontWeight: 400,
      // },
      // helperText: {
      //   fontSize: '.75rem',
      //   lineHeight: 1,
      //   fontWeight: 400,
      // },
      // inputText: {
      //   fontSize: '1rem',
      //   lineHeight: 1,
      //   fontWeight: 400,
      // },
    },

    MuiCard: {
      root: {
        background: '#fff',
        // boxShadow:
        // 	'0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
        padding: '15px',
        borderRadius: '4px',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(50,50,93,0.11), 0 1px 3px rgba(0,0,0,0.08)',
        transition: 'all 0.15s ease',
        opacity: 1,
      },
      label: {
        fontSize: '.938rem',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
      },
      contained: {
        padding: '12px 32px',
        '& $label': {
          fontSize: '.938rem',
          fontWeight: 500,
        },
        '&:hover': {
          opacity: '0.85 !important',
        },
      },
      containedSizeSmall: {
        padding: '8px 24px',
        '& $label': {
          fontSize: '.938rem',
          fontWeight: 600,
        },
        '&:hover': {
          opacity: '0.85 !important',
        },
      },
      containedSizeLarge: {
        padding: '16px 32px',
        '& $label': {
          fontSize: '1.25rem',
          fontWeight: 600,
        },
        '&:hover': {
          opacity: '0.85 !important',
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: '#77b0e0',
      },
    },
  },
};

const dark: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#0d3054',
    },
    secondary: {
      main: '#0b1e26',
    },
    error: {
      main: '#870505',
    },
    warning: {
      main: '#964c00',
    },
    info: {
      main: '#09539c',
    },
    success: {
      main: '#034d06',
    },
    text: {
      primary: '#f8f9fa',
      secondary: '#e4e7eb',
      hint: '#bbbcbd',
      disabled: '#ccc',
    },
    background: {
      default: '#1c2c33',
      paper: '#162024',
    },
  },
};

const themes: { [key: string]: Theme } = {
  light: makeTheme(light),
  dark: makeTheme(dark),
};

export const themeCookie = 'clthmvar';

export default themes;

import React, { useEffect } from 'react';
// import { Link } from 'gatsby';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#002D5C',
    cursor: 'pointer',
    fontWeight: 600,
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'underline',
    textDecorationColor: 'transparent',
    textDecorationThickness: '3px',
    position: 'relative',
    transition: 'all .2s',
    fontSize: '1rem',
    paddingRight: '18px',
    
    // [theme.breakpoints.down('lg')]: {
    //   paddingRight: '8px',
    // },
    '&:hover': {
      color: '#2A7ABC',
    },
  },
  activeLink: {
    textDecoration: 'underline',
    textDecorationColor: '#2A7ABC',
    textDecorationThickness: '3px',
  },
}));

const StandaloneNavLinks = ({}) => {
  const classes = useStyles();

  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const links = [
    { title: 'Perks & Benefits', link: 'perks' },
    { title: 'By the Numbers', link: 'numbers' },
    { title: 'Core Values', link: 'values' },
    { title: 'Interview Process', link: 'interview' },
  ];

  return (
    <Typography style={{ display: 'flex' }}>
      {links.map((item, index) => (
        <Link
          key={index}
          to={item.link}
          className={classes.link}
          activeClass={classes.activeLink}
          role='button'
          spy
          smooth
          offset={index > 1 ? -150 : -50}
        >
          {item.title}
        </Link>
      ))}
    </Typography>
  );
};

export default StandaloneNavLinks;

import React, { useEffect } from 'react';

import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  standaloneLinkContainer: {
    padding: '15px 0',
    borderBottom: `1px #FFFFFF solid`,
  },
  standaloneLink: {
    textDecoration: 'none',
    color: 'white',
  },
}));

const MobileStandaloneNavLinks = ({ link = 'Example', title = 'Example', close }) => {
  const classes = useStyles();

  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <Link to={link} spy smooth className={classes.standaloneLink}>
      <Grid
        container
        alignItems="center"
        onClick={close}
        className={classes.standaloneLinkContainer}
      >
        {/* <FontAwesomeIcon
          icon={['fad', icon]}
          style={{
            marginRight: '.5rem',
            color: 'white',
            marginLeft: '1rem',
            fontSize: '1.25rem',
          }}
        /> */}
        <Typography
          style={{ fontSize: '1.25rem', fontWeight: 500, color: '#fff', paddingLeft: '1rem' }}
        >
          {title}
        </Typography>
      </Grid>
    </Link>
  );
};

export default MobileStandaloneNavLinks;

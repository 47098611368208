import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

import { StaticImage } from 'gatsby-plugin-image';

import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    //padding: theme.spacing(3, 2),
    padding: '2rem',
    //marginTop: 'auto',
    bottom: '0',
    position: 'relative',
    width: '100%',
    backgroundColor: '#F8FBFF',
    color: '#002D5C',
    overflowX: 'hidden',
    boxShadow: 'none',
    //zIndex: 3,
  },
  headerText: {
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'left',
  },
  linkText: {
    textDecoration: 'none',
    marginBottom: '10px',
    color: '#002D5C',
    fontSize: 16,
    textAlign: 'left',
    opacity: '1',
    transition: 'opacity .25s ease-in-out',
    '&:hover': {
      opacity: '0.6',
    },

    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto',
      minWidth: 'auto',
    },
  },
  linkTextSocial: {
    opacity: '1',
    maxWidth: '25px',
    transition: 'opacity .25s ease-in-out',
    '&:hover': {
      opacity: '0.6',
    },
  },
  linkTextAddress: {
    textDecoration: 'none',
    marginBottom: '10px',
    color: '#002D5C',
    fontSize: 16,
    textAlign: 'left',
    // opacity: '1',
    // transition: 'opacity .25s ease-in-out',
    // '&:hover': {
    // 	opacity: '0.6',
    // },

    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto',
      minWidth: 'auto',
    },
  },

  footerLogo: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      maxWidth: '192px',
      width: '100%',
    },
  },

  footerSocialContainer: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
}));

const Footer: FC = () => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');
  return (
    <footer className={classes.footer}>
      <Container fixed>
        <Grid container direction="row">
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} lg={2} className={classes.footerLogo}>
              <Link to="https://www.workwave.com" target="_blank" aria-label="Opens in a new tab">
                <StaticImage
                  src="../../images/logos/footer-logo.svg"
                  alt="logo"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </Link>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.footerSocialContainer}>
              <Link to="/contact-us/" className={classes.linkText}>
                Copyright© {new Date().getFullYear()}
              </Link>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.footerSocialContainer}>
              <Link
                to="https://www.workwave.com/privacy-policy/"
                className={classes.linkText}
                target="_blank"
                aria-label="Opens in a new tab"
              >
                Privacy Policy
              </Link>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.footerSocialContainer}>
              <Link
                to="https://www.workwave.com/terms-of-use/"
                className={classes.linkText}
                target="_blank"
                aria-label="Opens in a new tab"
              >
                Terms of Use
              </Link>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.footerSocialContainer}>
              <Link
                to="https://www.workwave.com/end-of-life-policy/"
                className={classes.linkText}
                target="_blank"
                aria-label="Opens in a new tab"
              >
                End of Life Policy
              </Link>
            </Grid>

            <Grid item xs={12} lg={2} className={classes.footerSocialContainer}>
              {' '}
              <a
                className={classes.linkTextSocial}
                href="https://www.facebook.com/WorkWaveCo/"
                target="_blank"
                aria-label="Opens in a new tab"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <FontAwesomeIcon
                  title="facebook"
                  style={{
                    height: med ? 'auto' : '25px',
                    width: med ? 'auto' : '25px',
                    maxWidth: '25px',
                    color: '#002D5C',
                  }}
                  icon={['fab', 'square-facebook']}
                />
              </a>
              <a
                className={classes.linkTextSocial}
                href="https://twitter.com/WorkWave/"
                target="_blank"
                aria-label="Opens in a new tab"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                {' '}
                <FontAwesomeIcon
                  title="twitter"
                  style={{
                    height: med ? 'auto' : '25px',
                    width: med ? 'auto' : '25px',
                    maxWidth: '25px',
                    color: '#002D5C',
                    marginLeft: med ? '3px' : '5px',
                  }}
                  icon={['fab', 'square-x-twitter']}
                />
              </a>
              <a
                className={classes.linkTextSocial}
                href="https://www.instagram.com/workwave/"
                target="_blank"
                aria-label="Opens in a new tab"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                {' '}
                <FontAwesomeIcon
                  title="instagram"
                  style={{
                    height: med ? 'auto' : '25px',
                    width: med ? 'auto' : '25px',
                    maxWidth: '25px',
                    color: '#002D5C',
                    marginLeft: med ? '2px' : '5px',
                  }}
                  icon={['fab', 'square-instagram']}
                />
              </a>
              <a
                className={classes.linkTextSocial}
                href="https://www.youtube.com/user/MarathonDataLLC/"
                target="_blank"
                aria-label="Opens in a new tab"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <FontAwesomeIcon
                  title="youtube"
                  style={{
                    height: med ? 'auto' : '25px',
                    width: med ? 'auto' : '25px',
                    maxWidth: '25px',
                    color: '#002D5C',
                    marginLeft: med ? '6px' : '9px',
                  }}
                  icon={['fab', 'square-youtube']}
                />
              </a>
              <a
                className={classes.linkTextSocial}
                href="https://www.linkedin.com/company/workwave/"
                target="_blank"
                aria-label="Opens in a new tab"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                {' '}
                <FontAwesomeIcon
                  title="linkedin"
                  style={{
                    height: med ? 'auto' : '25px',
                    width: med ? 'auto' : '25px',
                    maxWidth: '25px',
                    color: '#002D5C',
                    marginLeft: med ? '3px' : '5px',
                  }}
                  icon={['fab', 'linkedin']}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* <Container maxWidth="md">
        <Typography variant="body1" color="textSecondary">
          © {new Date().getFullYear()}, Built with
          {` `}
          <Link href="https://www.gatsbyjs.org" color="inherit">
            Gatsby
          </Link>
          ,{` `}
          <Link href="https://www.typescriptlang.org" color="inherit">
            Typescript
          </Link>{' '}
          and
          {` `}
          <Link href="https://material-ui.com/" color="inherit">
            @Material-ui
          </Link>
          ,
        </Typography>
      </Container> */}
    </footer>
  );
};

export default Footer;
